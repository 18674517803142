body {
  --primary-color: #5f9e9f;
  --secondary-color: #9dbaba;
  --font-color: #d8e5e5;
  --font-color-medium: rgba(255, 255, 255, 0.25);
  --font-color-light: rgba(196, 196, 196, 0.1);
  --bg-color: #222222;
  --bg-light-color: #3f3f3f;
  --bg-transparent: rgba(62, 63, 69, 0.85);
  --item-color: #333333;
  --both-white: #d8e5e5;

  &.light {
    --primary-color: #5f9e9f;
    --secondary-color: #9dbaba;
    --font-color: #3e4450;
    --font-color-medium: rgba(135, 122, 122, 0.5);
    --font-color-light: rgba(62, 68, 80, 0.1);
    --bg-color: #e7e7e7;
    --bg-light-color: #f2f2f2;
    --bg-transparent: rgba(242, 242, 242, 0.75);
    --item-color: #cbd3d4;
    --both-white: #d8e5e5;
  }
  margin: 0;
  font-family: "Poppins", "Noto Sans KR", sans-serif;
  background-color: var(--bg-color);
  color: var(--font-color);
  overflow: hidden;
  box-sizing: border-box;
}
